
import { mapState } from "vuex"
import _ from "lodash"

export default {
  layout: "no-auth",
  head() {
    const web_title = "เข้าสู่ระบบ - Oho Chat"
    const web_description =
      "โอ้โหแชท ระบบจัดการแชทบริการลูกค้า เพิ่มประสิทธิภาพทีมแอดมิน ครบจบในที่เดียว"

    return {
      title: web_title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: web_description + " | " + web_title,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `${process.env.web_url}/login`,
        },
      ],
    }
  },
  data() {
    return {
      logo_image: require("~/assets/images/logo.svg"),
      line_icon: require("~/assets/images/icon-line.svg"),
      mascot_hello: require("~/assets/images/oho-mascot-hello.svg"),
      video:
        "https://storage.googleapis.com/oho-public-content/web-app/Signup_page_video.mp4",
    }
  },
  computed: {
    ...mapState({
      oho_user: (state) => state.oho_user,
    }),
    fb_access_token() {
      return this.$route.hash
        ? this.$route.hash.split("access_token=")[1].split("&")[0]
        : null
    },
  },
  created() {
    if (this.oho_user) {
      if (process.client) window.location.replace("/select-business")
    }

    if (this.$route.query.action === "token_expired") {
      this.$message({
        message: "เซสชั่นของคุณหมดอายุ กรุณาล็อคอินใหม่อีกครั้ง",
        type: "info",
        duration: 0,
        showClose: true,
      })
    }
  },
}
