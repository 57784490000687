import { render, staticRenderFns } from "./login.vue?vue&type=template&id=f9a1be1e&scoped=true&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&id=f9a1be1e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9a1be1e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginCard: require('/builds/oho/oho-web-app/components/LoginCard.vue').default,ContactInfo: require('/builds/oho/oho-web-app/components/ContactInfo.vue').default})
