
export default {
  data() {
    return {
      line_icon: require("~/assets/images/icon-line.svg"),
    }
  },

  mounted() {},

  methods: {},
}
